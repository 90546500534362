import { useEffect, useState } from 'react';

const useGetScreenshot = (screenshotId) => {
    // should probably include a check to see if this is still valid
    const screenshot = `${
        process.env.REACT_APP_API_SERVER
    }/items/screenshots/${encodeURIComponent(screenshotId)}`;

    return screenshot;
};

export default useGetScreenshot;
