import React from 'react';
import styled from 'styled-components';

const Headers = {
    h1: styled.h1``,
    h2: styled.h2`
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        margin-bottom: 16px;
        display: block;
        ${props => props.border && 'border-bottom: 1px solid #eee;' }
        padding-bottom: 2px;
    `,
    h3: styled.h3``,
    h4: styled.h4``
};

const Header = ({ variant= 'h1', children, ...rest }) => {
    const component = Headers[variant];
    return React.createElement(component,rest, children);
};

export default Header;
