import _ from 'lodash';
import React, { useState } from 'react';
import { FlagIcon } from 'react-flag-kit';
import styled from 'styled-components';
import HelpText from './HelpText';
import './Translation.css';
import Header from '../typography/Header';
import Button from '../button';
import { useFormik } from 'formik';

const Container = styled.div`
    display: grid;
    grid-template-areas: '.' '.' '.';
    grid-template-rows: 4fr 1fr 64px;
    font-size: 14px;
    overflow: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 100vh;

    > * {
        padding: 16px;
    }

    > div:nth-child(2) {
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    }

    > div:last-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px;
        display: flex;
        flex: 1 auto;
        justify-content: flex-end;
        align-content: center;
    }

    #translation-container {
        overflow: auto;
    }
`;

const PageInfo = styled.span`
    display: inline-block;
    font-size: 12px;
    margin-right: 16px;
    color: #333;
`;

const Toolbar = styled.div`
    display: flex;
    flex: 1 auto;
    flex-wrap: wrap;
    width: 100%;

    > div {
        display: flex;
        flex: 1 auto;
        width: 50%;
    }

    > div:last-child {
        justify-content: flex-end;
        align-items: center;

        button {
            margin-right: 8px;
            height: 32px;
        }
    }
`;

const useGetFlagInfo = (lang) => {
    const mapping = {
        EN: ['English', 'US'],
        DE: ['German', 'DE'],
        ES: ['Spanish', 'ES'],
        FR: ['French', 'FR'],
        JA: ['Japanese', 'JP'],
        NL: ['Netherlands', 'NL'],
    };

    if (!mapping[lang]) {
        return [lang, lang.toUpperCase()];
    }

    return mapping[lang];
};

const Translation = ({
    rows,
    language,
    onNextPageClick,
    onPrevPageClick,
    onSaveClick,
    page,
    totalPages,
}) => {
    const [invalidIcon, setInvalidIcon] = useState(false);
    const [langName, code] = useGetFlagInfo(language);

    const submitHandler = async (values) => {
        const deltaRows = _.chain(values)
            .map((row, rowIdx) =>
                // reduce down to just our changed rows
                row.reduce((acc, cell, cellIdx) => {
                    return rows[rowIdx][cellIdx].value === cell.value
                        ? acc
                        : [...acc, cell];
                }, [])
            )
            .flatten()
            .compact()
            .value();

        if (_.isEmpty(deltaRows) || _.isNil(deltaRows)) {
            console.log('no updates');
            return;
        }

        onSaveClick(deltaRows);
    };

    const pageHandler = (cbFn) => () => {
        const values = formik.values;
        submitHandler(values);
        cbFn();
    };
    const formik = useFormik({
        initialValues: rows,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Container>
                <div id="translation-container">
                    <Toolbar>
                        <div>
                            <Header variant="h2">Translations</Header>
                        </div>
                        <div>
                            <PageInfo>
                                (Page {page} of {totalPages})
                            </PageInfo>
                            <Button
                                variant="secondary"
                                onClick={pageHandler(onPrevPageClick)}
                                disabled={page === 1}
                            >
                                Prev Page
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={pageHandler(onNextPageClick)}
                                disabled={totalPages === page}
                            >
                                Next Page
                            </Button>
                        </div>
                    </Toolbar>

                    <div className="translation-row subtitle-row">
                        <label>
                            <FlagIcon code="US" size={16} /> English
                        </label>
                        <label>
    {!invalidIcon && <FlagIcon code={code} size={16} onError={() => setInvalidIcon(true)} />} {langName}
                        </label>
                    </div>
                    {formik.values.map((r, idx) => {
                        const englishCell = _.find(r, {
                            header: 'EN',
                        });
                        // since this is backed by a spreadsheet it can be entered in possibly multiple ways
                        const fIndex = _.findIndex(r, o => 
                            o.header === language.toUpperCase() || 
                            o.header === language.toLowerCase() || 
                            o.header === language
                        );

                        if (fIndex === -1) {
                            
                            return null;
                        }
                        const foreignLanguageCell = r[fIndex];
                        return (
                            <div
                                key={englishCell.range}
                                className="translation-row"
                            >
                                <label>{englishCell.value}</label>
                                <textarea
                                    name={`[${idx}][${fIndex}].value`}
                                    onChange={formik.handleChange}
                                    value={foreignLanguageCell.value}
                                    placeholder="Please enter your translation here"
                                />
                            </div>
                        );
                    })}
                </div>
                <div id="translation-help-content">
                    <HelpText />
                </div>
                <div id="translation-action-bar">
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </div>
            </Container>
        </form>
    );
};

export default Translation;
