import React, { useState } from 'react';
import { useLanguages as useQueryApiLanguage } from '../../hooks/useLanguage';
import { useTranslationGroups } from '../../hooks/useQueryResults';
import Translation from './Translation';
import './Translation.css';
import styled from 'styled-components';
import Screenshot from './Screenshot';
import ErrorBoundary from '../ErrorBoundary';
import Spinner from 'react-spinkit';

const Container = styled.div`
    display: grid;
    grid-template-areas: '. .';
    grid-template-columns: 3fr 1fr;
    height: inherit;
`;

const Indicator = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20;
`;

const Main = () => {
    const {
        loading,
        nextPage,
        prevPage,
        data,
        groupId,
        currentPage,
        totalPages,
    } = useTranslationGroups();
    const [saving, setSaving] = useState(false);
    const language = useQueryApiLanguage();

    const onSave = async (diffs) => {
        try {
            setSaving(true);

            const res = await fetch(
                `${process.env.REACT_APP_API_SERVER}/items/update`,
                {
                    method: 'POST',
                    body: JSON.stringify(diffs),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            if (res.ok) {
                console.log('passed');
            } else {
                console.warn('failed');
            }
            setSaving(false);
        } catch (err) {
            console.warn('failed', err);
            setSaving(false);
        }
    };

    return (
        <Container>
            <ErrorBoundary>
                {loading && (
                    <Indicator>
                        <Spinner name="circle" />
                    </Indicator>
                )}
                {saving && (
                    <Indicator>
                        <Spinner name="circle" />
                    </Indicator>
                )}
                <Translation
                    rows={data}
                    language={language}
                    onNextPageClick={nextPage}
                    onPrevPageClick={prevPage}
                    page={currentPage + 1}
                    totalPages={totalPages}
                    onSaveClick={onSave}
                />
                <Screenshot screenshotName={groupId} />
            </ErrorBoundary>
        </Container>
    );
};

export default Main;
