import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useGetScreenshot from '../../hooks/useGetScreenshot';

const Container = styled.div`
    height: 100%;
    overflow: auto;
`;

const ResizeableImage = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
`;

const PlaceHolderText = styled.span`
    display: flex;
    flex: 1 auto;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #666;
`;

const Screenshot = ({ screenshotName }) => {
    const [loadFailed, setLoadFailed] = useState(false);
    const imageUrl = useGetScreenshot(screenshotName);

    useEffect(() => {
        setLoadFailed(false);
    }, [screenshotName]);

    if (!imageUrl) {
        return <label>Placeholder</label>;
    }

    const onImgError = () => {
        console.log('Error loading image');
        setLoadFailed(true);
    };

    return (
        <Container>
            {!loadFailed && (
                <ResizeableImage
                    src={imageUrl}
                    alt="screenshot"
                    onError={onImgError}
                />
            )}
            {loadFailed && (
                <PlaceHolderText>No Preview available</PlaceHolderText>
            )}
        </Container>
    );
};

export default Screenshot;
