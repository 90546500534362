import React from 'react';
import styled from 'styled-components';
import Header from '../typography/Header';

const KeyHeader = styled.span`
    display: block;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;
`;

const HelpTextSection = styled.div`
    font-size: 12px;
`;

const KeyTable = styled.table`
    border-collapse: collapse;

    tr > td:first-child {
        width: 100px;
    }

    tr > td {
        margin-bottom: 4px;
    }
`;

const HelpText = () => {
    const textFormattingKeys = {
        '<bold>': 'Start of bold word',
        '</bold>': 'End of bold word',
        '<color>': 'Start of Colored Text',
        '</color>': 'End of Colored Text',
        '%s': 'Dynamic Text',
        '\\n': 'New Line',
        "'": "To Display '",
        '#N/a': 'No translation available',
    };
    return (
        <HelpTextSection>
            <Header variant="h2">Formatting Tips</Header>
            <p>
                In order to keep the Text translations constant, please follow
                the below instructions
                <br />
                Insert the following symbols before & after the text, thanks
            </p>
            <div>
                <KeyHeader>KEY DESCRIPTION</KeyHeader>
                <KeyTable>
                    <tbody>
                        {Object.keys(textFormattingKeys).map((key, idx) => (
                            <tr key={idx}>
                                <td>{key}</td>
                                <td>{textFormattingKeys[key]}</td>
                            </tr>
                        ))}
                    </tbody>
                </KeyTable>
            </div>
        </HelpTextSection>
    );
};

export default HelpText;
