// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export const useTranslationGroups = () => {
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [groupId, setGroupId] = useState();
    const [data, setData] = useState([]);

    const nextPage = () => {
        const newPageId = Math.min(currentPage + 1, totalPages - 1);
        setCurrentPage(newPageId);
    };

    const prevPage = () => {
        const newPageId = Math.max(0, currentPage - 1);
        setCurrentPage(newPageId);
    };

    // get the groups available
    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_SERVER}/items/groups`)
            .then((r) => r.json())
            .then((d) => {
                setGroups(d.data);
                setTotalPages(d.data.length);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error('Error fetching group', err);
            });
    }, []);

    // go to a new page
    useEffect(() => {
        if (_.isNil(groups) || _.isEmpty(groups)) {
            return;
        }

        const groupId = encodeURIComponent(groups[currentPage]);
        setGroupId(groups[currentPage]);

        const dataUrl = `${process.env.REACT_APP_API_SERVER}/items/groups/${groupId}`;

        setLoading(true);
        fetch(dataUrl)
            .then((res) => res.json())
            .then((d) => {
                setData(d.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error('An error occured', err);
                setLoading(false);
            });
    }, [currentPage, groups]);

    return {
        loading,
        nextPage,
        prevPage,
        data,
        currentPage,
        totalPages,
        groupId,
    };
};
