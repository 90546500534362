import React from 'react';
import styled from 'styled-components';

const DefaultButton = styled.button`
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    border: 1px solid transparent;
    padding: 4px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 250ms;

    &:disabled {
        cursor: initial;
        background-color: #ccc !important;
        color: #000 !important;
        opacity: 0.75;
    }
`;

const Primary = styled(DefaultButton)`
    background-color: #027affee;
    &:hover {
        background-color: #027aff;
    }
`;

const Secondary = styled(DefaultButton)`
    background-color: transparent;
    color: #000;
    border: 1px solid #ccc;

    &:hover {
        border: 1px solid transparent;
        color: #fff;
        background-color: #027aff;
    }
`;

const Variants = {
    primary: Primary,
    secondary: Secondary,
};

const Button = ({ variant, children, ...rest }) => {
    const component = Variants[variant];
    return React.createElement(component, rest, children);
};

export default Button;
