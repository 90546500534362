import React from 'react';
import Translation from './Translation';
import { Normalize } from 'styled-normalize';

function App() {
    return (
        <>
            <Normalize />
            <Translation />
        </>
    );
}

export default App;
